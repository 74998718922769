import { TherapistType } from '@/src/types/TherapistStateType'
import useGetDiagnosis from '@/src/components/recommendedTherapist/hooks/useGetDiagnosis'
import useTherapists from '@/src/hooks/useTherapists'

const useGetMatchingTherapist = (therapistId: number) => {
  const { data } = useGetDiagnosis()
  const { therapists } = useTherapists('visible')

  const matchingTherapistData = data?.therapistMatches?.find((therapist) => therapist.therapistId === therapistId)
  const matchingTherapist = therapists?.find((item: TherapistType) => item.therapistId === therapistId)

  return { matchingTherapistData, matchingTherapist, data }
}

export default useGetMatchingTherapist
