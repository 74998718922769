import Markdown from 'markdown-to-jsx'
import React from 'react'
import { be } from '../../utilities/bliss'
import Icon from '../icons/Icon'
import { RegisterOptions } from 'react-hook-form'
import RadioInput from './RadioInput'

export type RadioOption = {
  readonly [optionString: string]: any
}

type CheckIconType = {
  readonly checked: boolean
}

type RadioPropsType = {
  readonly onChange: (RadioOption) => void
  readonly value: string
  readonly label: string
  readonly checked: boolean
  readonly name?: string
  readonly moduleName?: string
  readonly className?: string
  readonly icon?: React.ReactNode
  readonly type?: string
  readonly extendedContent?: React.ReactNode
  readonly registerOptions?: RegisterOptions
  readonly orientation?: 'left' | 'right'
  readonly useMarkdown?: boolean
  readonly hookForm?: boolean
  readonly onClick?: React.MouseEventHandler<HTMLInputElement>
}

const CheckIcon: React.FC<CheckIconType> = ({ checked }) => {
  return checked ? <Icon icon="radio-on" /> : <Icon icon="radio-off" />
}

const Radio = ({
  value,
  label,
  onChange,
  checked,
  className,
  name,
  icon,
  type,
  registerOptions,
  extendedContent,
  onClick,
  orientation = 'right',
  useMarkdown = true,
  hookForm = false,
}: RadioPropsType) => {
  return (
    <div key={`${value}-item`} className={`${be('radio', 'item', [type])} ${className}`}>
      <label className={be('radio', 'label', [type, checked ? 'checked' : ''])}>
        {icon}

        {hookForm ? (
          <RadioInput name={name} value={value} registerOptions={registerOptions} onClick={onClick} />
        ) : (
          <input
            checked={checked}
            className={be('radio', 'input')}
            id={`${value}`}
            name={`${value}`}
            onChange={() => {
              onChange({ value, label })
            }}
            type="radio"
            value={value}
          />
        )}

        <div className={be('label-wrapper', 'content', '', 'items-center')}>
          <div className="flex">
            {orientation === 'left' && <CheckIcon checked={checked} />}
            <div className="flex flex-wrap">
              <div className={`inline-block ${extendedContent ? 'mr-8' : ''}`}>
                {useMarkdown ? <Markdown>{label}</Markdown> : label}
              </div>
              {extendedContent && <div className="inline-block">{extendedContent}</div>}
            </div>
          </div>
          {orientation === 'right' && (
            <div className="ml-2">
              <CheckIcon checked={checked} />
            </div>
          )}
        </div>
        {type === 'submit' && <Icon icon="arrow" size="sm" color="primary" />}
      </label>
    </div>
  )
}

export default Radio
