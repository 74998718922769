export enum DiagnosisGuideSteps {
  name,
  serviceSelect,
  content1,
  topics,
  content2,
  test,
  content3,
  therapistPreferences,
  insurance,
  content4,
  time,
}

export enum SpecializationCategory {
  FEATURED = 0,
  PERSONAL_PROBLEMS = 1,
  PERSONAL_GROWTH = 2,
  FAMILY_AND_INTIMACY = 3,
  ADDICTIONS_AND_PERSONALITY_DISORDERS_AND_HEALTH = 4,
}

export type DiagnosisType = 'lead' | 'emotions' | 'past' | 'personality'

export const HIGH_BASE_VALUE_MARKER = 'high'
export const LOW_BASE_VALUE_MARKER = 'low'

export type DiagnosisFields = {
  lead: number
  emotions: number
  past: number
  personality: number
  specialization: string[]
  sex: number
  service: number
  therapyActivities: string[]
}

export type DiagnosisFieldsPayload = Omit<DiagnosisFields, 'specialization' | 'service' | 'therapyActivities'> & {
  specialization: number[]
  therapyActivities: number[]
  services: number[]
}

export type DiagnosisResponse = {
  diagnosis: DiagnosisFieldsPayload & {
    goal: number | null
    id: number
    userId: number
  }
  therapists: number[]
}

export type ExtendedDiagnosisFields = DiagnosisFields & {
  goal: number | null
  services: number[]
  id: number
  userId: number
  therapistAgeGroup: string
  maxPrice: string
  insuranceId: number
  partOfDay: number
  name?: string
}

export type ExtendedDiagnosisData = DiagnosisFieldsPayload & {
  goal: number | null
  services: number[]
  id: number
  userId: number
  therapistAgeGroup: number
  maxPrice: number
  insuranceId: number
  partOfDay: number
  name?: string
}

export type TherapistMatch = {
  therapistId: number
  ageGroup: number
  partsOfDay: number[]
  styleMatch: number
}

export type ExtendedDiagnosisResponse = {
  diagnosis: ExtendedDiagnosisData
  therapists: number[]
  therapistMatches: TherapistMatch[]
}

export type SpecializationType = {
  readonly name: string
  readonly id: number
  readonly category: number
}

export type SpecializationCheckboxType = {
  readonly value: string
  readonly label: string
  readonly category: number
}
