import useSWRImmutable from 'swr/immutable'
import { useApis } from '../../../contexts/api-context'
import { ApiEndpoints } from '../../../services/api'

const fetchAllowedPremiumContent = async (api: ApiEndpoints) => {
  const res = await api.getAllowedPremiumContent()
  return res.data as string[]
}

const useAllowedPremiumContent = () => {
  const { api } = useApis()
  const { data, mutate: refetchAllowedPremiumContent } = useSWRImmutable(['usePremiumContent'], () =>
    fetchAllowedPremiumContent(api),
  )

  return {
    allowedPremiumContent: data,
    refetchAllowedPremiumContent,
  }
}

export default useAllowedPremiumContent
