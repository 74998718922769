import colors from '../../rebrand/colors.js'

export const getBgColor = (color: string) =>
  ({
    default: '',
    white: 'bg-white',
    sand: 'bg-sand-500',
    blue: 'bg-blue-500',
    yellow: 'bg-yellow-500',
    pink: 'bg-pink-500',
    petrol: 'bg-petrol-500',
  }[color])

export const getTextColor = (color: string) =>
  ({
    default: '',
    light: 'text-white',
    dark: 'text-petrol-500',
  }[color])

export const getColor = (color: string) =>
  ({
    blue: colors.blue[500],
    yellow: colors.yellow[500],
    pink: colors.pink[500],
  }[color])
