import { isNewBrandingActive } from '@/src/rebrand/utilities'
import { be } from '../../utilities/bliss'
import { getTranslate } from '../../utilities/i18n'
import IconText from '../icons/IconText'
import IconTextRebrand from '../icons/IconTextRebrand'

const Recommendations = ({ recommendations }: { recommendations: number }) => {
  const { t } = getTranslate()
  return isNewBrandingActive ? (
    <IconTextRebrand
      iconName="heart-rebrand"
      text={t('component.reviewList.recommendPlural', {
        count: recommendations,
      })}
    />
  ) : (
    <IconText
      className={be('therapist', 'info-proves-item')}
      text={t('component.reviewList.recommendPlural', {
        count: recommendations,
      })}
    />
  )
}

export default Recommendations
