'use client'

import useAllowedPremiumContent from '../premiumContent/hooks/usePremiumContent'
import React from 'react'
import { getTranslate } from '../../utilities/i18n'
import LessonTeaserSidebar from '../onlineLessons/LessonTeaserSidebar'
import useFlags from '../../hooks/useFlags'
import { VIDEO_LESSON_TYPE } from '../premiumContent/premiumContent.constants'
import LinkWithQueryForwarding from '../button/LinkWithQueryForwarding'

const pickRandomItems = (arr, numItems) => {
  const shuffledArray = arr.slice().sort(() => Math.random() - 0.5)
  return shuffledArray.slice(0, numItems)
}

const RecommendedVideos = ({ videoLessons }) => {
  const { t } = getTranslate()
  const { allowNewContent } = useFlags()
  const { allowedPremiumContent: premiumContent } = useAllowedPremiumContent()
  const { allowPoshurshemo } = useFlags()

  const brand = allowPoshurshemo ? VIDEO_LESSON_TYPE.POSHURSHEMO : VIDEO_LESSON_TYPE.BRAINEE

  const braineeLessons = videoLessons?.filter(
    (lesson) => lesson.attributes.type === null || lesson.attributes.type === VIDEO_LESSON_TYPE.BRAINEE,
  )
  const poshurshemoLessons = videoLessons?.filter((lesson) => lesson.attributes.type === VIDEO_LESSON_TYPE.POSHURSHEMO)

  const lessons = allowPoshurshemo ? poshurshemoLessons : braineeLessons

  const videoLessonsType = lessons[0]
    ? lessons[0].attributes.type === null
      ? 'brainee'
      : lessons[0].attributes.type
    : ''

  const isLocked = !premiumContent || !premiumContent?.includes(videoLessonsType.toUpperCase())

  const items = pickRandomItems(lessons, 2)

  if (!allowNewContent) {
    return null
  }

  return (
    <>
      <h5 className="text-md mb-4 font-bold text-secondary">{t(`component.counselling.recommendedTitle`)}</h5>

      {items?.map((item, index) => {
        const lesson = item.attributes
        const author = lesson.lessonInfo?.lessonAuthor

        return (
          <LinkWithQueryForwarding
            key={`video-${index}`}
            href={isLocked ? `/articles/${brand}` : `/articles/${brand}/${lesson.slug}`}
            className={`flex self-stretch text-text-color hover:no-underline`}
          >
            <LessonTeaserSidebar lesson={lesson} author={author} />
          </LinkWithQueryForwarding>
        )
      })}
    </>
  )
}

export default RecommendedVideos
