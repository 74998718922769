'use client'

import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer'
import React from 'react'

type Props = { content: RootNode[]; blocks?: any; modifiers?: any }

const StrapiBlocksRenderer: React.FC<Props> = (props) => {
  if (!props.content) return null
  return (
    <BlocksRenderer
      {...props}
      blocks={{ paragraph: ({ children }) => <p className="last:mb-0">{children}</p>, ...(props.blocks || {}) }}
    />
  )
}

export default StrapiBlocksRenderer
