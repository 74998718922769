import { useAppSelector } from '../store/hooks'
import { fetchingSelector } from '../store/selectors/GeneralSelectors'
import useSWRImmutable from 'swr/immutable'
import { useApis } from '../contexts/api-context'
import { TherapistType } from '../types/TherapistStateType'
import { ApiEndpoints } from '../services/api'
import useUser from './useUser'
import { useMemo } from 'react'
import { processTherapistList } from '@/src/utilities/apiUtils'

const fetchFunction = async (api: ApiEndpoints): Promise<TherapistType[]> => {
  const res = await api.getOpenTherapists()
  return processTherapistList(res.data)
}

interface TherapistsData {
  readonly therapists: TherapistType[]
  readonly isLoadingTherapists: boolean
}

const useTherapists = (view: 'all' | 'visible'): TherapistsData => {
  const { api } = useApis()
  const { data: allTherapists } = useSWRImmutable('useTherapists', () => fetchFunction(api))
  const { user } = useUser()

  const subscriptionTherapistIds = useMemo(
    () => user.userSubscriptions?.map((subscription) => subscription.therapistId) || [],
    [user.userSubscriptions],
  )

  const visibleOrVisitedTherapists = useMemo(
    () =>
      allTherapists?.filter(
        (therapist) =>
          therapist.visible ||
          user.visitedTherapistIds.includes(therapist.therapistId) ||
          subscriptionTherapistIds.includes(therapist.therapistId),
      ),
    [allTherapists, user.visitedTherapistIds, subscriptionTherapistIds],
  )

  const isLoadingAll = !allTherapists
  const isLoadingVisibleOrVisited = useAppSelector((state) => fetchingSelector(state, 'therapists'))

  return {
    therapists: view === 'all' ? allTherapists : visibleOrVisitedTherapists,
    isLoadingTherapists: isLoadingAll || isLoadingVisibleOrVisited,
  }
}

export default useTherapists
