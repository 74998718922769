export const sessionStorageJsonItem = <T>(key: string) => {
  const setSessionData = (data: T): void => {
    try {
      sessionStorage.setItem(key, JSON.stringify(data))
    } catch (error) {}
  }

  const getSessionData = (): T | null => {
    if (typeof sessionStorage === 'undefined') {
      return null
    }

    try {
      const sessionData = sessionStorage.getItem(key)
      const parsed = JSON.parse(sessionData)
      return parsed
    } catch (error) {
      return null
    }
  }

  const clearSessionData = (): void => {
    try {
      sessionStorage.removeItem(key)
    } catch (error) {}
  }

  return { setSessionData, getSessionData, clearSessionData }
}
