import { be } from '../../utilities/bliss'
import { RegisterOptions, useFormContext } from 'react-hook-form'

type RadioInputType = {
  readonly name: string
  readonly value: string
  readonly registerOptions?: RegisterOptions
  readonly onClick?: React.MouseEventHandler<HTMLInputElement>
}

const RadioInput: React.FC<RadioInputType> = ({ value, name, registerOptions, onClick, ...props }) => {
  const { register } = useFormContext()

  return (
    <input
      {...props}
      {...register(name, registerOptions)}
      value={value}
      className={be('radio', 'input')}
      type="radio"
      onClick={onClick}
    />
  )
}

export default RadioInput
