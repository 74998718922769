import React, { useMemo, useContext, createContext } from 'react'
import { ApisType } from '../store/createApis'

type ApiContextType = ApisType

type Props = { apis: ApisType; setToken: any; children: React.ReactNode }

const ApisContext = createContext<ApiContextType>({} as ApiContextType)

const ApisProvider: React.FC<Props> = ({ apis, setToken, ...props }) => {
  const value = useMemo(() => ({ ...apis, setToken }), [apis, setToken])

  return <ApisContext.Provider value={value} {...props} />
}

const useApis = () => useContext(ApisContext)

export { ApisProvider, useApis }
