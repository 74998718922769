import Select from 'react-select'

import { bm } from '../../utilities/bliss'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import React from 'react'
import ErrorMessage from './ErrorMessage'
import { OptionType } from './types'
import { isNewBrandingActive } from '@/src/rebrand/utilities'

type SelectControllerProps = {
  readonly label?: React.ReactNode
  readonly name: string
  readonly options: OptionType[]
  readonly type?: string
  readonly required?: boolean
  readonly className?: string
  readonly placeholder?: string
  readonly prefixText?: string
  readonly disabled?: boolean
  readonly registerOptions?: RegisterOptions
}

const SelectController: React.FC<SelectControllerProps> = ({
  label,
  name,
  options,
  required,
  className,
  registerOptions,
  placeholder,
  prefixText,
  type = 'text',
  ...props
}) => {
  const { register, control } = useFormContext()
  return (
    <>
      <div className={`form-group ${className}`}>
        {label && (
          <label htmlFor={name}>
            {label}
            {required &&
              (isNewBrandingActive ? (
                <sup className="static text-base">*</sup>
              ) : (
                <sup className="right[-5px] relative top-[10px] text-[40px] text-primary">*</sup>
              ))}
          </label>
        )}

        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          render={({ field: { onChange, value, name } }) => (
            <Select
              id={name}
              {...props}
              {...register(name, registerOptions)}
              className={bm('react-select', ['w-full', 'searchable'])}
              classNamePrefix="react-select-searchable"
              name={name}
              onChange={(selectedOption) => onChange(selectedOption)}
              options={options}
              value={value}
              placeholder={placeholder}
            />
          )}
        />
        <ErrorMessage name={name} />
      </div>
    </>
  )
}

export default SelectController
