import { useFormError } from './hooks/useFormError'
import { be } from '../../utilities/bliss'
import { getTranslate } from '../../utilities/i18n'

type ErrorMessageProps = {
  readonly name: string
  readonly className?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ name, className }) => {
  const { t } = getTranslate()
  const error = useFormError(name)

  if (error === undefined) {
    return null
  }
  return (
    <div className={be('error', 'field', [], className)}>
      <span>{t(`${error.message}`)}</span>
    </div>
  )
}

export default ErrorMessage
