import React from 'react'
import Icon from './Icon'
import { cn } from '@/src/rebrand/utilities'
import Markdown from 'markdown-to-jsx'

type Props = { iconName: string; text: React.ReactNode; className?: string }

const IconTextRebrand: React.FC<Props> = ({ iconName, text, className = '' }) => {
  return (
    <div className={cn('flex items-center', className)}>
      <Icon icon={iconName} size="sm" className="mr-2" />
      <div className="text-sm font-bold">{typeof text === 'string' ? <Markdown>{text}</Markdown> : text}</div>
    </div>
  )
}

export default IconTextRebrand
