import SelectController from '@/src/components/formFields/SelectController'
import React from 'react'
import CheckboxFieldsList from '@/src/components/formFields/CheckboxFieldsList'
import RadioList from '@/src/components/formFields/RadioList'
import { useFormContext } from 'react-hook-form'
import capitalize from 'lodash/capitalize'
import { convertToHyphenatedString } from '@/src/components/strapiForm/utils'
import { SelectType } from '@/src/components/strapiForm/types'

export type SelectionsInputProps = {
  data: SelectType
}

const FormSelectionsInput: React.FC<SelectionsInputProps> = ({ data }) => {
  const { watch } = useFormContext()
  const name = convertToHyphenatedString(data.label)
  const radioSelectedOption = watch(name)

  const selectOptions = data.options.split(',').map((word) => {
    const label = word.trim()
    return {
      value: label,
      label: capitalize(label),
    }
  })

  const input =
    {
      select: (
        <SelectController
          name={name}
          required={data.isRequired}
          label={data.label}
          options={selectOptions}
          placeholder={data.placeholder}
        />
      ),
      checkbox: (
        <div className="w-full">
          {data.label && <div className="mb-2 text-base font-bold uppercase">{data.label}</div>}
          <div className="grid grid-cols-1 gap-4">
            <CheckboxFieldsList options={selectOptions} name={name} />
          </div>
        </div>
      ),
      radio: (
        <div className="w-full">
          {data.label && <div className="mb-2 text-base font-bold uppercase">{data.label}</div>}
          <div className="grid grid-cols-1 gap-4">
            <RadioList
              className="flex w-full flex-wrap"
              radioClassName="w-full"
              type="plain"
              orientation="left"
              name={name}
              options={selectOptions}
              selectedOption={{ value: radioSelectedOption }}
              hookForm
            />
          </div>
        </div>
      ),
    }[data.type] || null

  return input
}

export default FormSelectionsInput
