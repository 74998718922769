'use client'

import {
  Carousel,
  CarouselClickItem,
  CarouselContent,
  CarouselItem,
  useCarousel,
  usePositionButton,
} from '@/src/components/pageBlocks/carousel/EmblaCarousel'
import { cn } from '@/src/rebrand/utilities'
import React from 'react'
import Image from 'next/image'
import { ImageAttributes, PageBlockType } from '@/src/components/pageBlocks/types'

const ControlItemsMobile = () => {
  const { api } = useCarousel()
  const { selectedIndex } = usePositionButton(api)
  return (
    <div className="mt-10 flex items-center justify-center gap-[6px]">
      {[...Array(4)].map((item, index) => {
        const isSelected = selectedIndex === index
        return (
          <CarouselClickItem key={`mobile-${index}`} index={index}>
            <div
              className={cn(
                'h-3 w-3 rounded-full transition-all duration-500',
                isSelected ? 'transition-delay-250 w-7 bg-primary' : 'transition-delay-250 bg-sand-500',
              )}
            />
          </CarouselClickItem>
        )
      })}
    </div>
  )
}

type PropsImageContent = { image: ImageAttributes; text: string; index: number }

const ImageContent: React.FC<PropsImageContent> = ({ image, text, index }) => {
  const { api } = useCarousel()
  const { selectedIndex } = usePositionButton(api)

  return (
    <CarouselItem className="min-w-[240px] flex-auto flex-shrink-0 flex-grow-0">
      <div className="mx-2">
        <div className={cn('mx-auto flex max-w-[300px]', selectedIndex === index ? 'visible' : 'invisible')}>
          <h3 className="mb-6 w-full text-center text-xl font-semibold text-secondary">{text}</h3>
        </div>

        {image ? (
          <Image
            src={image.url}
            alt={image.alternativeText || text}
            width={image.width}
            height={image.height}
            sizes="300px"
            className="max-w-[300px]"
          />
        ) : null}
      </div>
    </CarouselItem>
  )
}

/*
 * for more info about partial display of items check:
 * https://github.com/davidjerleke/embla-carousel/issues/118
 * */
const SliderFeaturesMobile: React.FC<{
  data: PageBlockType
}> = ({ data }) => {
  return (
    <div className="block tablet:hidden">
      <Carousel
        opts={{
          loop: true,
          align: 'center',
        }}
        className="overflow-hidden"
      >
        <CarouselContent className="flex w-full items-end">
          {(data.slide || []).map((slide, index) => {
            const img = slide.imageMobile?.data?.attributes
            return <ImageContent key={`slide-mobile-${slide.id}`} image={img} text={slide.title} index={index} />
          })}
        </CarouselContent>
        <ControlItemsMobile />
      </Carousel>
    </div>
  )
}

export default SliderFeaturesMobile
