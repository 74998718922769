'use client'

import React, { useLayoutEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Lottie, { LottieProps } from 'react-lottie'

type Props = { launchInView?: boolean } & LottieProps

const defaultOptions = {
  loop: false,
  autoplay: false,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const HedepyLottie: React.FC<Props> = ({ launchInView = false, options, ...props }) => {
  const [isStopped, setIsStopped] = useState(launchInView ? true : false)

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust based on when you want the animation to start (0.5 means 50% in view)
  })

  useLayoutEffect(() => {
    if (inView) {
      setIsStopped(false)
    }
  }, [inView])

  return (
    <div ref={ref}>
      <Lottie isStopped={isStopped} options={{ ...defaultOptions, ...options }} {...props} />
    </div>
  )
}

export default HedepyLottie
