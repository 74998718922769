export const NUMBER_OF_BLOG_ARTICLES = 3
export const NUMBER_OF_TESTS = 8
export const NUMBER_OF_LESSONS = 2
export const NUMBER_OF_ARTICLES = (isPublic) => {
  if (isPublic) return 6
  return 3
}

export const ARTICLE_PAGE_SLUG = 'articles'

export enum AUTH_CONTENT_SLUGS {
  BLOG = 'blog',
  ABOUT_THERAPY = 'about-therapy',
  TOPICS = 'topics',
  BRAINEE = 'brainee',
  POSHURSHEMO = 'poshurshemo',
  ATAIRU = 'atairu',
  TESTS = 'tests',
}

export enum VIDEO_LESSON_TYPE {
  BRAINEE = 'brainee',
  POSHURSHEMO = 'poshurshemo',
  ATAIRU = 'atairu',
}
