import React from 'react'
import Icon from '../icons/Icon'
import { getTranslate } from 'src/utilities/i18n'
import Markdown from 'markdown-to-jsx'
import { RadixButtonLink } from '@/src/rebrand/buttonRadix'

type Props = {
  readonly className?: string
}

const CounsellingTeaser: React.FC<Props> = ({ className }) => {
  const { t } = getTranslate()
  return (
    <div className={`flex w-full justify-center ${className}`}>
      <div className={`w-full rounded-3xl bg-white p-7 shadow-hedepy`}>
        <div className={`"-m-3 'tablet:flex-column' flex flex-col items-center text-center`}>
          <Icon icon="chat-bubbles" className="mr-3" color="primary" size="slg" />
          <div className={`p-3 text-center text-xl font-bold leading-6 tablet:!text-center`}>
            {t('component.counseling.banner.title')}
          </div>
          <div className="mb-7 text-sm text-text-color-light">
            <Markdown>{t(`component.counseling.banner.descActiveBenefit`)}</Markdown>
          </div>

          <div className="p-3 leading-6">
            <RadixButtonLink href={'/counselling'}>{t('component.counseling.startChat.button')}</RadixButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CounsellingTeaser
