import React from 'react'
import { getTranslate } from '@/src/utilities/i18n'
import { STYLE_LIMITS } from '@/src/components/recommendedTherapist/constants'

type Props = {
  readonly value: number
  readonly label: string
}

const StyleMatch: React.FC<Props> = ({ value, label }) => {
  const { t } = getTranslate()

  const isPoor = value < STYLE_LIMITS.ok

  const isOk = value >= STYLE_LIMITS.ok && value < STYLE_LIMITS.great

  return (
    <>
      <div className={`relative mt-[65px] flex w-full`}>
        <div className={`h-3 w-1/4 rounded-md bg-pink-500`} />
        <div className={`h-3 w-1/4 rounded-md bg-blue-500 `} />
        <div className={`h-3 w-1/4 rounded-md bg-secondary `} />
        <div className={`h-3 w-1/4 rounded-md bg-secondary `} />

        <div className="absolute -top-1/2 -ml-3" style={{ left: `${value}%` }}>
          <div className={`flex h-6 w-6 items-center justify-center rounded-full bg-primary`}>
            <div className={`h-[14px] w-[14px] rounded-full bg-white`} />
          </div>

          <div
            className={`absolute ${isPoor ? 'left-[80px] ' : isOk ? '' : '-right-[90px]'}
               -top-[45px] flex max-w-[164px] -translate-x-1/2 transform whitespace-nowrap rounded-md bg-yellow-500 px-2 py-1  text-xs font-bold text-petroleum`}
          >
            <div
              className={`absolute -bottom-[3px] ${
                isPoor ? 'left-0 translate-x-1/2' : isOk ? 'left-1/2' : 'right-0 -translate-x-1/2'
              } -bottom-[3px] h-4 w-4  rotate-45 transform bg-yellow-500`}
            ></div>
            <span className="relative z-10 w-[175px] whitespace-nowrap text-center">
              {t(`component.recommendedTherapist.style.${label}.label`)}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default StyleMatch
