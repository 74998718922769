import React from 'react'
import { YouTubeEmbed } from '@next/third-parties/google'

type VideoPropTypes = {
  className?: string
  videoId: string
  height?: number
}

const YoutubeVideo: React.FC<VideoPropTypes> = ({ className, height, videoId }) => (
  <div className={`${className}`}>
    <YouTubeEmbed videoid={videoId} height={height} />
  </div>
)

export default YoutubeVideo
