'use client'

import { be } from '../../utilities/bliss'
import { ComponentNames } from '../university/componentNames'
import React from 'react'
import { getTranslate } from '../../utilities/i18n'
import CounsellingTeaser from './CounsellingTeaser'
import useFlags from '../../hooks/useFlags'
import ArticleAnchors, { textToAnchor } from './ArticleAnchors'
import RecommendedVideos from './RecommendedVideos'
import RecommendeContent from './RecommendedContent'
import BackButton from '../button/BackButton'
import Citation from './Citation'
import ArticleHeaderImg from '@/src/components/counselling/ArticleHeaderImg'
import ArticleHeaderInfo from '@/src/components/counselling/ArticleHeaderInfo'
import FAQ from '@/src/components/university/FAQ'
import Markdown from 'markdown-to-jsx'
import AnchorSection from '../university/AnchorSection'
import Icon from '../icons/Icon'
import ArticleVideo from '@/src/components/counselling/ArticleVideo'
import { ARTICLE_CATEGORIES } from './utils/constants'
import { rewriteKnownUrlToPublic } from 'roots/(publicContent)/_components/utils/rewriteLinks'
import { useSession } from '@/src/contexts/session-context'
import Image from 'next/image'
import { RadixButtonLink } from '@/src/rebrand/buttonRadix'
import { chooseCorrectColors } from './utils/chooseCorrectColors'
import useSWRImmutable from 'swr/immutable'
import { fetchAllImageDimensions } from '@/src/utilities/apiUtils'

type ImgProps = {
  alt: string
  title?: string
  src: string
  className?: string
}

const useImageDimensions = (url: string) => {
  const { data } = useSWRImmutable('useImageDimensions', fetchAllImageDimensions)
  return data ? data[url] : null
}

export const OptimizedImage: React.FC<ImgProps> = (props) => {
  const { t } = getTranslate()
  const isSvg = props.src.includes('.svg')
  const alt = props.alt ?? t('general.illustrationImage')
  const imgDimensions = useImageDimensions(props.src)

  if (isSvg) {
    const src = chooseCorrectColors(props.src)
    return <img {...props} src={src} loading="lazy" alt={alt} />
  }

  const imgProps = { ...props, alt: alt, sizes: '720px' }

  return imgDimensions ? (
    <Image {...imgProps} width={imgDimensions.w} height={imgDimensions.h} />
  ) : (
    <span className="relative flex h-[300px] big-phone:h-[400px]">
      <Image {...imgProps} fill className="max-w-max object-cover tablet:object-contain" />
    </span>
  )
}

const selectComponent = (componentName, item, key, isPublic = false, isLoggedIn = false) => {
  return (
    {
      [ComponentNames.richContent]: item.content && (
        <div key={key} className="overflow-x-auto">
          <Markdown
            key={`content-${key}`}
            options={{
              slugify: (str) => textToAnchor(str),
              overrides: { img: { component: OptimizedImage } },
            }}
          >
            {item.content}
          </Markdown>
        </div>
      ),
      [ComponentNames.anchor]: <AnchorSection key={key} section={item} />,
      [ComponentNames.buttonLink]: (
        <div className="my-12 flex justify-center">
          <RadixButtonLink
            variant={item.style === 'primary' ? 'default' : item.style}
            href={isLoggedIn ? item.link : rewriteKnownUrlToPublic(item.link)}
            className="normal-case"
          >
            {item.text}
            {item.withArrow && <Icon className="ml-6 text-white" icon="arrow" size="sm" />}
          </RadixButtonLink>
        </div>
      ),
      [ComponentNames.faq]: <FAQ key={`faq-${key}`} faqs={item} />,
      [ComponentNames.videoContent]: <ArticleVideo key={`article-video-${key}`} item={item} />,
      [ComponentNames.citation]: <Citation key={key} citation={item} isPublic={isPublic} />,
    }[componentName] ?? null
  )
}

type Props = {
  readonly data: any // @todo types
  readonly videoLessons: any
  readonly topics?: any
  readonly isPublic?: boolean
  readonly isMainContent?: boolean
  readonly diagnosisTeaser: React.ReactNode
  readonly baseLink?: string
}

const ArticleDetail: React.FC<Props> = ({
  data,
  videoLessons,
  topics,
  isPublic,
  isMainContent,
  diagnosisTeaser,
  baseLink,
}) => {
  const { t } = getTranslate()
  const { showCounsellingTeaser } = useFlags()
  const { isLoggedIn } = useSession()

  const articleContent = data?.counsellingPages?.data[0]?.attributes

  return (
    <div className="w-full">
      {!articleContent ? (
        <p>There is no content for this version of language.</p>
      ) : (
        <>
          <div className="flex flex-col justify-center tablet:flex-row">
            <div className="w-full overflow-x-hidden">
              <div className="px-4 pb-8 tablet:mr-8 big-tablet:px-16 big-tablet:pb-12 desktop:mr-0">
                <ArticleHeaderImg content={articleContent} baseLink={baseLink} />
                <h1 className="mb-4 text-secondary">{articleContent.title}</h1>
                <ArticleHeaderInfo content={articleContent} />
                <hr className="border-1 hidden w-full border-solid border-light tablet:block" />
                <div className="mt-8 tablet:hidden">
                  <ArticleAnchors content={articleContent.content} />
                </div>
              </div>
              <div
                className={be(
                  'counselling',
                  'content',
                  [],
                  'flex flex-wrap px-4 pb-8 tablet:mr-8 big-tablet:px-16 big-tablet:pb-10 desktop:mr-0',
                )}
              >
                <p>{articleContent.perex}</p>
                {articleContent.content?.map((item, key) => {
                  return selectComponent(item.__typename, item, `${item.__typename}-${key}`, isPublic, isLoggedIn)
                })}

                <div className="tablet:hidden">
                  {isMainContent ? (
                    <RecommendeContent topics={topics} />
                  ) : (
                    <RecommendedVideos videoLessons={videoLessons} />
                  )}
                </div>

                {!isPublic && (
                  <>
                    <hr className="hidden border-[2px] border-light laptop:block" />
                    <h2 className="w-full text-secondary">
                      {t(
                        `component.counselling.article.${
                          articleContent.category === ARTICLE_CATEGORIES.aboutTherapy ? 'subTitleTherapy' : 'subTitle'
                        }`,
                      )}
                    </h2>

                    <div className="mb-12 grid w-full grid-cols-1 gap-6 desktop:grid-cols-2">
                      {diagnosisTeaser}
                      {showCounsellingTeaser && <CounsellingTeaser />}
                    </div>

                    <div className="mb-13">
                      <BackButton icon="back" link={baseLink} text={t(`component.button.back`)} size="sm" />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={`order-1 mt-80 hidden w-full max-w-[280px] pt-4 tablet:order-2 tablet:block`}>
              <ArticleAnchors content={articleContent.content} />
              {isMainContent ? (
                <RecommendeContent topics={topics} />
              ) : (
                <RecommendedVideos videoLessons={videoLessons} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ArticleDetail
