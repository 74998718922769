import React from 'react'
import { bm } from '../../utilities/bliss'
import Radio from './Radio'
import type { RadioOption } from './Radio'

type RadioListTypeProps = {
  readonly options: RadioOption[]
  readonly onChange?: (option: RadioOption) => void
  readonly selectedOption?: RadioOption
  readonly name?: string
  readonly className?: string
  readonly moduleName?: string
  readonly radioClassName?: string
  readonly useMarkdown?: boolean
  readonly type?: string
  readonly extendedContent?: React.ReactNode
  readonly orientation?: 'left' | 'right'
  readonly hookForm?: boolean
  readonly onClick?: React.MouseEventHandler<HTMLInputElement>
}

const RadioList: React.FC<RadioListTypeProps> = ({
  options,
  onChange,
  selectedOption,
  moduleName,
  className,
  radioClassName,
  useMarkdown,
  name,
  type,
  onClick,
  orientation = 'right',
  hookForm,
}) => {
  const handleOnChange = (option: RadioOption) => {
    onChange(option)
  }

  return (
    <div className={bm(moduleName || 'radio', null, className || '')}>
      {Array.isArray(options) &&
        options.map(({ label, value, icon, extendedContent }: RadioOption) => {
          return (
            <Radio
              key={`radio-${label}`}
              checked={(typeof selectedOption === 'object' && selectedOption.value === value) || false}
              label={label}
              name={name}
              onChange={hookForm ? null : handleOnChange}
              value={value}
              moduleName={moduleName}
              className={radioClassName}
              icon={icon}
              extendedContent={extendedContent}
              useMarkdown={useMarkdown}
              type={type}
              orientation={orientation}
              hookForm={hookForm}
              onClick={onClick}
            />
          )
        })}
    </div>
  )
}
export default RadioList
