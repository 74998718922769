import Image from 'next/image'
import React from 'react'
import { getBgColor } from './utils/getBgColor'
import { isNewBrandingActive } from '@/src/rebrand/utilities'
import { chooseCorrectColors } from './utils/chooseCorrectColors'
import { getTranslate } from '@/src/utilities/i18n'

type Props = { readonly content: any; readonly baseLink: string }

const ArticleHeaderImg: React.FC<Props> = ({ content, baseLink }) => {
  const { t } = getTranslate()
  const imgFromStrapi = content.cover?.data?.attributes?.url
  const isSvg = imgFromStrapi?.includes('.svg')

  const imgSrc = isNewBrandingActive && imgFromStrapi && isSvg ? chooseCorrectColors(imgFromStrapi) : imgFromStrapi
  const imgAlt = content.cover.alt ?? content.title ?? t('general.illustrationImage')

  const headerBgColor = isNewBrandingActive ? 'bg-blue-500' : getBgColor(content.headerBackground, baseLink)

  return (
    <>
      <div className={`${headerBgColor} mb-8 h-[300px] w-full rounded-[20px]`}>
        <div className="relative flex h-full">
          {imgSrc &&
            (isSvg ? (
              <img src={imgSrc} loading="eager" alt={imgAlt} className="mx-auto p-6" />
            ) : (
              <Image
                alt={imgAlt}
                src={imgSrc}
                className="object-contain p-6"
                fill
                sizes="137px"
                loading="eager"
                priority
              />
            ))}
        </div>
      </div>
    </>
  )
}

export default ArticleHeaderImg
