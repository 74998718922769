import React from 'react'
import { cn } from '../rebrand/utilities'

type Props = { children: React.ReactNode; className?: string }

const TherapistSpecializationBadge: React.FC<Props> = ({ children, className = '' }) => {
  return <div className={cn('rounded-lg bg-sand-500 p-1 text-sm font-bold', className)}>{children}</div>
}

export default TherapistSpecializationBadge
