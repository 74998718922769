export enum ARTICLE_CATEGORIES {
  topic = 'topic',
  article = 'article',
  aboutTherapy = 'about-therapy', // strapi sends it this way with underscore
}

export const CONTENT_VIDEO_LESSONS = 'videoLessons' as const

export const CONTENT_TESTS = 'tests' as const

export type CONTENT_CATEGORIES = ARTICLE_CATEGORIES | typeof CONTENT_VIDEO_LESSONS | typeof CONTENT_TESTS
