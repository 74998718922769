import React from 'react'

import StyleMatch from '@/src/components/recommendedTherapist/StyleMatch'
import useGetMatchingTherapist from '@/src/components/diagnosis/guide/hooks/useGetMatchingTherapist'
import { styleMatchText } from '@/src/components/diagnosis/guide/utils'
import { IconSizeType } from '@/src/types/ComponentTypes'

type Props = {
  readonly therapistId: number
  readonly iconSize?: IconSizeType
  readonly title?: React.ReactNode
  readonly className?: string
  readonly listing?: boolean
}

const TherapeuticStyleProfile: React.FC<Props> = ({ therapistId, title, className = '', listing = false }) => {
  const { matchingTherapistData } = useGetMatchingTherapist(therapistId)

  const label = styleMatchText(matchingTherapistData?.styleMatch)

  const headline = title && <strong>{title}</strong>

  if (matchingTherapistData?.styleMatch === undefined) {
    return null
  }

  if (listing) {
    return (
      <>
        <hr className="-mx-5 -mb-1 mt-6" />
        <div className={className}>
          {headline}
          <StyleMatch value={matchingTherapistData?.styleMatch || 0} label={label} />
        </div>
      </>
    )
  }

  return matchingTherapistData ? (
    <div className={`${className} rounded-xl bg-white p-5`}>
      {headline}
      <StyleMatch value={matchingTherapistData.styleMatch} label={label} />
    </div>
  ) : null
}

export default TherapeuticStyleProfile
