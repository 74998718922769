import { getTranslate } from '@/src/utilities/i18n'

export const getFullName = ({
  person,
  titleAfter,
  titleBefore,
}: {
  person: { firstName: string; lastName: string; email?: string }
  titleBefore?: string
  titleAfter?: string
}) => {
  const { t } = getTranslate()
  if (!person) return t('general.unknownUser')

  const emailPrefix = person.email?.match(/^[^@]+/)[0]

  const isFirstNameEmailPrefix = person.firstName === emailPrefix

  const nameParts = [
    titleBefore,
    person.firstName,
    person.lastName,
    titleAfter,
    person.lastName ? null : isFirstNameEmailPrefix ? '' : `(${emailPrefix})`,
  ]

  return nameParts.filter(Boolean).join(' ')
}
