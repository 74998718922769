export enum ComponentNames {
  anchor = 'ComponentSectionAnchorSection',
  faq = 'ComponentSectionFaqSection',
  richContent = 'ComponentComponentRichContent',
  academy = 'Academy',
  articlesLinks = 'ComponentSectionArticlesLinksSection',
  ytVideo = 'ComponentComponentYoutubeVideo',
  videoContent = 'ComponentComponentVideoContent',
  note = 'ComponentComponentNote',
  buttonLink = 'ComponentComponentButtonLink',
  citation = 'ComponentSectionCitationSection',
}
