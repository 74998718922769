'use client'

import React from 'react'
import { useRouter } from 'next/navigation'
import Icon from '../icons/Icon'
import { be, bm } from '../../utilities/bliss'
import type { IconBaseType } from '../../types/ComponentTypes'
import Link from 'next/link'
import { Url } from 'next/dist/shared/lib/router/router'
import { RadixButton } from '@/src/rebrand/buttonRadix'

export type BackButtonPropsType = {
  linkAs?: string
  link?: Url
  text: string
  onClick?: () => void
  navigateBack?: boolean
} & IconBaseType

const BackButton = ({
  link,
  linkAs,
  text,
  color,
  size = 'sm',
  icon,
  onClick,
  navigateBack = false,
}: BackButtonPropsType) => {
  const router = useRouter()

  const handleRedirect = () => {
    if (typeof onClick === 'function') {
      onClick()
    } else if (navigateBack) {
      router.back()
    }
  }
  const renderButton = (onClick?: () => void) => (
    <RadixButton className={bm('back-button', [size || ''])} variant="inline" onClick={onClick}>
      {icon && <Icon className={be('back-button', 'back-icon')} color={color} icon={icon} size={size} />}
      <span className={be('back-button', 'text', [size || ''])}>{text}</span>
    </RadixButton>
  )

  return link ? (
    <Link href={link} as={linkAs || link}>
      {renderButton()}
    </Link>
  ) : (
    renderButton(handleRedirect)
  )
}

export default BackButton
