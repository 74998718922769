import { getTranslate } from 'src/utilities/i18n'
import React from 'react'
import Icon from '../icons/Icon'
import { AuthorType, LessonType } from './types'
import useFlags from '@/src/hooks/useFlags'
import { VIDEO_LESSON_TYPE } from '../premiumContent/premiumContent.constants'

type Props = {
  readonly lesson: LessonType
  readonly author: AuthorType
  readonly recommended?: boolean
}

const LessonTeaserSidebar: React.FC<Props> = ({ lesson, author, recommended }) => {
  const { t } = getTranslate()
  const { allowPoshurshemo } = useFlags()
  const brand = allowPoshurshemo ? VIDEO_LESSON_TYPE.POSHURSHEMO : VIDEO_LESSON_TYPE.BRAINEE

  return (
    <div className="flex w-full flex-col">
      <div className={`relative mb-4 flex h-[220px] overflow-hidden rounded-3xl bg-petroleum tablet:h-[9.6rem]`}>
        <div className={`my-5 ml-5 w-1/2`}>
          <div className="flex ">
            <div className={`shrink rounded-md bg-peach px-2 text-xs font-bold text-white`}>
              {t(`component.premiumContent.${brand}.tag`)}
            </div>
          </div>
          <div className={`text-md mt-1 line-clamp-4 font-semibold text-white`}>{author?.name}</div>
          <div className={`text-xs font-bold text-petroleum-light`}>{t(`component.premiumContent.authorTitle`)}</div>
          <div className={`absolute bottom-3 left-4 flex tablet:bottom-4`}>
            {lesson.videoSection?.videoLength !== undefined && lesson.videoSection?.videoLength !== null && (
              <div
                className={`mt-2 flex shrink items-center rounded-lg bg-white bg-opacity-10  px-2 py-1 text-xs font-bold text-white `}
              >
                <Icon icon="clock" color="primary" size="xs" className="mr-3" />
                <div>
                  {t('component.premiumContent.lessonFooter.minutePlural', {
                    count: lesson.videoSection.videoLength,
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="relative w-1/2">
          <div className="absolute -bottom-6 right-0">
            <img
              loading="lazy"
              alt={author?.thumbnail?.data?.attributes?.alternativeText}
              src={author?.thumbnail?.data?.attributes?.url}
              className="rounded-br-3xl"
            />
          </div>
        </div>
      </div>
      <div className="mb-6 flex items-center text-base font-bold text-text-color">
        <div className="line-clamp-2">{lesson?.title}</div>
        <Icon icon="arrow" size="sm" className="ml-7" color="primary" />
      </div>
    </div>
  )
}

export default LessonTeaserSidebar
