'use client'

import React, { useState } from 'react'
import TherapistProfileImage from './profiles/TherapistProfileImage'
import Link from 'next/link'
import { getFullName } from '../utilities/name'
import { RadixButtonLink } from '../rebrand/buttonRadix'
import Modal from './modal/Modal'
import TherapeuticStyleProfile from './recommendedTherapist/TherapeuticStyleProfile'
import { SpecializationOptionsType, TherapistType } from '../types/TherapistStateType'
import VerifiedTraining from './profiles/VerifiedTraining'
import Recommendations from './profiles/Recommendations'
import { getTranslate } from '../utilities/i18n'
import TherapistSpecializationBadge from './TherapistSpecializationBadge'
import { cn } from '../rebrand/utilities'
import YoutubeVideo from './YoutubeVideo'

type Props = {
  therapist: TherapistType
  href: any
  showTherapeuticScale?: boolean
  allowBookingForFree?: boolean
  displayedPrice: number
  therapistId: number
  specializations: SpecializationOptionsType
  prefetch?: boolean
  openModalFromOutside?: (videoId: string) => void | null
}

const TherapistCardContent: React.FC<Props> = ({
  href,
  showTherapeuticScale,
  allowBookingForFree,
  displayedPrice,
  therapistId,
  specializations,
  therapist,
  prefetch,
}) => {
  const { t } = getTranslate()

  const [videoVisible, setVideoVisible] = useState(false)

  const {
    firstName,
    lastName,
    image,
    titleAfter,
    titleBefore,
    experience,
    majorSpecializations = [],
    minorSpecializations = [],
    description,
    verifiedCertificate,
    recommendations,
    videoId,
  } = therapist

  const displayRecommendations = Boolean(recommendations)
  const displayVerifiedTraining = typeof verifiedCertificate === 'number'

  return (
    <div className="flex w-full flex-col rounded-xl bg-white">
      <div className="w-full p-5 pb-0">
        <div className="mb-6 flex items-center">
          <TherapistProfileImage
            {...{ href, prefetch }}
            identifier={`${firstName} ${lastName}`}
            openModal={videoId ? () => setVideoVisible(true) : null}
            source={image}
            height={110}
            width={100}
            className="mr-4 flex-shrink-0"
          />
          <div>
            <Link className="h3 text-xl leading-6 text-secondary" {...{ prefetch, href }}>
              {getFullName({ person: { firstName, lastName }, titleAfter, titleBefore })}
            </Link>
            <div className="mt-1 text-xs font-bold text-primary">
              {t('therapistProfile.experience', { experience })}
            </div>
          </div>
        </div>
        <div className="-m-1 flex flex-wrap">
          {majorSpecializations.map((tag: number) => (
            <Tag key={tag}>{specializations?.[tag]}</Tag>
          ))}
          {minorSpecializations.length > 0 && <Tag>+ {minorSpecializations.length}</Tag>}
        </div>
        {(displayRecommendations || displayVerifiedTraining) && (
          <div className="mt-6">
            {displayVerifiedTraining && <VerifiedTraining verified={verifiedCertificate} />}
            {displayRecommendations && displayVerifiedTraining && <div className="mt-3" />}
            {displayRecommendations && <Recommendations recommendations={recommendations} />}
          </div>
        )}
        {description && <div className="mt-6 text-sm">{description}</div>}
      </div>
      <div className="grow"></div>
      <div className="w-full px-5">
        <div className={cn('mt-6 text-center text-sm', allowBookingForFree ? 'line-through' : 'font-bold')}>
          {t('therapistProfile.price', {
            price: displayedPrice,
          })}
        </div>
        {allowBookingForFree && <div className="text-center font-bold">{t('therapistProfile.priceFirstFree')}</div>}
        <RadixButtonLink className="mt-4 w-full" size="small" {...{ prefetch, href }}>
          {t('therapistProfile.openProfile')}
        </RadixButtonLink>
      </div>
      <div className="w-full p-5 pt-0">
        {showTherapeuticScale && <TherapeuticStyleProfile therapistId={therapistId} listing />}
      </div>
      <Modal className="block" closeModal={() => setVideoVisible(false)} size="lg" video visible={videoVisible}>
        <YoutubeVideo className="overflow-hidden rounded-2xl" videoId={videoId} />
      </Modal>
    </div>
  )
}

const Tag = ({ children }) => (
  <div className="p-1">
    <TherapistSpecializationBadge>{children}</TherapistSpecializationBadge>
  </div>
)

export default TherapistCardContent
