import { isNewBrandingActive } from '@/src/rebrand/utilities'

export const chooseCorrectColors = (source: string, isDarkBg?: boolean) => {
  if (!source) return source

  const isSvg = source.includes('.svg')

  if (!isSvg || !isNewBrandingActive) return source

  if (isDarkBg)
    return (
      `/api/v2/recolor-svg?url=${encodeURIComponent(source)}` +
      `&oldColor=${encodeURIComponent('#FF8F67')}&oldColor=${encodeURIComponent('#490811')}` +
      `&newColor=${encodeURIComponent('#E6CFE3')}&newColor=${encodeURIComponent('#FFFFFF')}`
    )

  return (
    `/api/v2/recolor-svg?url=${encodeURIComponent(source)}` +
    `&oldColor=${encodeURIComponent('#FF8F67')}&oldColor=${encodeURIComponent('#490811')}&oldColor=${encodeURIComponent(
      '#FFFFFF',
    )}&oldColor=${encodeURIComponent('white')}` +
    `&newColor=${encodeURIComponent('#E6CFE3')}&newColor=${encodeURIComponent('#0B4752')}&newColor=${encodeURIComponent(
      '#0B4752',
    )}&newColor=${encodeURIComponent('#0B4752')}`
  )
}
