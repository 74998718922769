'use client'

import React, { useState } from 'react'
import Bubble from '../Bubble'
import type { ArticleAuthor } from '../onlineLessons/types'
import { ShareButton } from '../referral/ShareButtons'
import Icon from '../icons/Icon'
import { getTranslate } from '../../utilities/i18n'
import copyToClipboard from 'copy-to-clipboard'
import { usePathname } from 'next/navigation'
import { RadixButton } from '@/src/rebrand/buttonRadix'

type Props = { readonly content: any }

const ArticleAuthor: React.FC<ArticleAuthor> = ({ author }) => {
  return (
    <div className="flex items-center">
      {author?.thumbnail?.data && (
        <Bubble disableOffset imgUrl={author.thumbnail.data.attributes.url} sizeClass="w-12 h-12" />
      )}

      {(author?.desc || author?.name) && (
        <div className="ml-5 flex flex-col justify-center text-sm">
          <span>{author.desc}</span>
          <strong>{author?.name}</strong>
        </div>
      )}
    </div>
  )
}

const ArticleHeaderInfo: React.FC<Props> = ({ content }) => {
  const { t } = getTranslate()
  const pathname = usePathname()

  const [isTextCopied, setIsTextCopied] = useState(false)

  const currentUrl = `${process.env.NEXT_PUBLIC_FE_URL}${pathname}`
  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`

  const copyText = () => {
    copyToClipboard(currentUrl)
    setIsTextCopied(true)
  }

  return (
    <>
      <div className="flex flex-col justify-between pt-6 tablet:flex-row">
        <ArticleAuthor author={content.author} />

        <hr className="border-1 w-full border-solid border-light tablet:hidden" />

        <div className="flex items-center justify-between text-sm tablet:ml-5 tablet:block tablet:flex-col">
          <div className="text-sm font-bold text-bordeaux-lighter tablet:mb-2 tablet:text-xs">
            {t(`component.premiumContent.shareTitle`)}
          </div>
          <div className="flex items-center justify-center">
            <ShareButton
              icon="facebook"
              href={fbShareUrl}
              className={'mr-4 h-8 w-8 justify-center p-0'}
              iconSize="xs"
              newWindow
            />
            <RadixButton
              variant={isTextCopied ? 'light' : 'default'}
              className={`flex h-8 w-8 items-center justify-center p-0 uppercase`}
              size="small"
              onClick={copyText}
            >
              <Icon icon="copy" size="xs" color="white" />
            </RadixButton>
          </div>
        </div>
      </div>
      {isTextCopied && (
        <div className="text-color-primary mt-2 text-right text-sm font-bold">{t(`general.info.copied`)}</div>
      )}
    </>
  )
}

export default ArticleHeaderInfo
