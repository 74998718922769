import React from 'react'
import { getTranslate } from 'src/utilities/i18n'
import { be } from '../../utilities/bliss'
import IconText from '../icons/IconText'
import { isNewBrandingActive } from '@/src/rebrand/utilities'
import IconTextRebrand from '../icons/IconTextRebrand'

type Props = { verified: number }

const VerifiedTraining: React.FC<Props> = ({ verified }) => {
  const { t } = getTranslate()

  return isNewBrandingActive ? (
    <IconTextRebrand iconName="frequent-rebrand" text={t(`therapistProfile.verification.${verified}`)} />
  ) : (
    <IconText
      className={be('therapist', 'info-proves-item')}
      icon="frequent"
      text={t(`therapistProfile.verification.${verified}`)}
    />
  )
}

export default VerifiedTraining
