import Link from 'next/link'
import React from 'react'
import { getTranslate } from '../../utilities/i18n'
import TopicTeaserSidebar from '../onlineLessons/TopicTeaserSidebar'

const RecommendeContent = ({ topics }) => {
  const { t } = getTranslate()

  return (
    <>
      <h5 className="text-md mb-4 font-bold text-secondary">{t(`component.counselling.recommendedTitle`)}</h5>

      {topics?.map((item, index) => {
        const topic = item.attributes

        return (
          <Link
            key={`video-${index}`}
            href={`/content/${topic.slug}`}
            className={`flex max-w-[280px] self-stretch text-text-color hover:no-underline`}
          >
            <TopicTeaserSidebar topic={topic} />
          </Link>
        )
      })}
    </>
  )
}

export default RecommendeContent
