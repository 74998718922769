export const AGE_GROUP = {
  NO_PREFERENCE: 0,
  UP_TO_35: 1,
  BETWEEN_36_AND_55: 2,
  MORE_OR_EQUAL_56: 3,
}

export const PART_OF_DAY = {
  NO_PREFERENCE: 0,
  MORNING: 1,
  AFTERNOON: 2,
  EVENING: 3,
}

export const GENDER = {
  MAN: 0,
  WOMAN: 1,
  NO_PREFERENCE: 2,
}

export const STYLE_LIMITS = {
  ok: 25,
  good: 50,
  great: 75,
}
