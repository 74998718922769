import React, { useMemo, useContext, createContext, useEffect, useState } from 'react'
import { UserType } from '../types/UserStateType'
import { jwtDecode } from 'jwt-decode'
import UserActions from '../redux/UserRedux'
import { useApis } from './api-context'
import { useAppSelector } from '../store/hooks'
import { useRouter } from 'next/router'
import { REFRESH_TOKEN_KEY } from '../constants/storage'

type SessionContextType = { user?: UserType; isAuthenticating: boolean; isLoggedIn: boolean }

const SessionContext = createContext<SessionContextType>({} as SessionContextType)

const SessionProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { api } = useApis()
  const router = useRouter()

  const [isAuthenticating, setIsAuthenticating] = useState(true)

  const user = useAppSelector((state) => state.user)

  useEffect(() => {
    const doRefresh = async () => {
      const jwtToken = jwtDecode(refreshToken)
      if (jwtToken.exp > +new Date() / 1000) {
        await api.refresh(refreshToken, [UserActions.getUserDataRequest(null, true, { event: 'loginSuccessful' })])
      } else {
        setIsAuthenticating(false)
      }
    }

    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
    // we are already doing the refresh in impersonate
    if (refreshToken && router.pathname !== '/impersonate/[token]') {
      doRefresh()
    } else {
      setIsAuthenticating(false)
    }
  }, [])

  const value = useMemo(() => ({ user, isAuthenticating, isLoggedIn: !!user?.id }), [user, isAuthenticating])

  return <SessionContext.Provider value={value} {...props} />
}

const useSession = () => useContext(SessionContext)

export { SessionProvider, useSession }
