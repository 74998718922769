import React from 'react'
import Markdown from 'markdown-to-jsx'
import Icon from '../icons/Icon'
import { be, bm } from '../../utilities/bliss'
import { getTranslate } from 'src/utilities/i18n'
import { isNewBrandingActive } from '@/src/rebrand/utilities'

type ModalPropTypes = {
  children: React.ReactNode
  centered?: boolean
  className?: string
  closeModal?: null | (() => void)
  isScrollable?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  title?: string
  noGutters?: boolean
  visible: boolean
  onBack?: () => void
  backText?: string
  video?: boolean
}

const Modal: React.FC<ModalPropTypes> = ({
  children,
  centered = true,
  className = '',
  closeModal,
  isScrollable = false,
  size = 'md',
  title,
  noGutters = false,
  visible,
  backText,
  onBack,
  video = false,
}) => {
  const { t } = getTranslate()
  if (!visible) {
    return null
  }

  const shouldClose = typeof closeModal === 'function'

  return (
    <div
      className={bm('modal', [noGutters ? 'no-gutters' : '', video ? 'video' : ''], className)}
      role="dialog"
      tabIndex={-1}
    >
      {shouldClose && <div className={be('modal', 'close-bg')} onClick={closeModal} />}
      <div
        className={bm(
          'modal-dialog',
          [centered ? 'centered' : '', isScrollable ? 'scrollable' : ''],
          `modal-${size}`,
          true,
        )}
        role="document"
      >
        <div className="modal-content">
          {(title || backText || shouldClose) && (
            <div className="modal-header">
              {backText && (
                <button
                  className={be('modal', 'back', [], isNewBrandingActive ? '!font-normal !normal-case' : '')}
                  onClick={onBack}
                  type="button"
                >
                  <Icon icon="back" size="native" svgType="symbolic" color="primary" />
                  {t(backText)}
                </button>
              )}
              {title && (
                <div className={be('modal', 'title')}>
                  <h3>
                    <Markdown>{t(title, {}, { fallback: '' })}</Markdown>
                  </h3>
                </div>
              )}
              {shouldClose && (
                <button className={be('modal', 'close')} onClick={closeModal} type="button">
                  <Icon icon="cross" size="sm" svgType="symbolic" color="white" />
                </button>
              )}
            </div>
          )}
          <div className="modal-body">
            <div className={be('modal', 'scroll-view')}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
