import Icon from '../icons/Icon'
import copyToClipboard from 'copy-to-clipboard'
import { useState } from 'react'
import { getTranslate } from 'src/utilities/i18n'
import { getReferralPrice } from './utils/getReferralPrice'
import { IconSizeType } from '../../types/ComponentTypes'
import notifyMobileApp, { NotifyAppMessages } from '@/src/utilities/notifyMobileApp'
import useIsAppWebView from '@/src/hooks/useIsAppWebView'
import { RadixButton, RadixButtonLink } from '@/src/rebrand/buttonRadix'

type ShareButtonProps = {
  readonly text?: string
  readonly icon?: string
  readonly href?: string
  readonly className?: string
  readonly iconSize?: IconSizeType
  readonly newWindow?: boolean
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  icon,
  text,
  href,
  className,
  iconSize,
  newWindow = false,
}) => {
  return (
    <RadixButtonLink
      className={`${className} flex items-center uppercase`}
      size="small"
      href={href}
      newWindow={newWindow}
    >
      {icon && <Icon icon={icon} size={`${iconSize ?? 'sm'}`} color="white" className={`${text ? 'mr-2' : ''}`} />}
      {text}
    </RadixButtonLink>
  )
}

type ShareButtonsProps = {
  readonly shareData: {
    readonly code: string
    readonly noOfUses: number
  }
  readonly className?: string
  readonly alignCenter?: boolean
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ shareData, className }) => {
  const { t } = getTranslate()
  const isWebview = useIsAppWebView()
  const [isTextCopied, setIsTextCopied] = useState(false)

  const code = shareData?.code
  const subject = t('component.referral.mail.subject')
  const body = t('component.referral.mail.body', {
    code,
    price: getReferralPrice(),
  })

  const emailUrl = `mailto:?subject=${subject}&body=${body}`

  const buttonProps = isWebview
    ? { onClick: () => notifyMobileApp(NotifyAppMessages.OPEN_EMAIL, { email: emailUrl }) }
    : { href: emailUrl }

  const copyText = () => {
    copyToClipboard(code)
    setIsTextCopied(true)
  }

  return (
    <div className={className}>
      <RadixButton
        variant={isTextCopied ? 'light' : 'default'}
        className={`flex items-center uppercase`}
        size="small"
        onClick={copyText}
      >
        <Icon icon="copy" size="sm" color="white" className="mr-2" />
        {isTextCopied ? t('component.button.copied') : t('component.button.copy')}
      </RadixButton>
      {/* temporarily hide share buttons */}
      {/*<ShareButton text={t('component.button.facebook')} icon="facebook" href={FbShareUrl} />
      <ShareButton text={t('component.button.instagram')} icon="instagram" />*/}
      <ShareButton {...buttonProps} text={t('component.button.email')} icon="mail-stroke" />
    </div>
  )
}

export default ShareButtons
