import { cn } from '@/src/rebrand/utilities'
import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'
import { PageBlockCommonType } from '../types'

type Props = VariantProps<typeof variants> & {
  children: React.ReactNode
  name: string
  className?: string
  common?: PageBlockCommonType
  noBottomSpacing?: boolean
}

const variantsConfig = {
  color: {
    // targetting next sibling needs to be done through arbitrary class in tailwind
    // see https://stackoverflow.com/a/76562080
    white: 'bg-white [&+.bg-white]:!pt-0',
    sand: 'bg-sand-500 [&+.bg-sand-500]:!pt-0',
    petrol: 'bg-petrol-500 text-white [&+.bg-petrol-500]:!pt-0',
  },
  size: { default: 'py-14 tablet:py-24', minimal: '', sm: 'py-12 tablet:py-20', xs: 'py-8' },
}
const variants = cva('relative', {
  variants: variantsConfig,
  defaultVariants: { color: 'white', size: 'default' },
})

const PageBlock: React.FC<Props> = ({
  children,
  color: colorProps,
  size,
  name,
  className = '',
  noBottomSpacing,
  common,
}) => {
  const colorStrapi = common?.backgroundColor as any
  const isStrapiColorValid = Object.keys(variantsConfig.color).includes(colorStrapi)
  const color = isStrapiColorValid ? colorStrapi : colorProps

  return (
    <div
      className={cn(variants({ color, size }), className, noBottomSpacing && '!pb-0')}
      data-block-name={name || 'unknown'}
    >
      {children}
    </div>
  )
}

export default PageBlock
