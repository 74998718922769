import React from 'react'
import YoutubeVideo from '@/src/components/YoutubeVideo'
import Markdown from 'markdown-to-jsx'

const ArticleVideo = ({ item }) => {
  return (
    <div className="w-full justify-center">
      <div className="mb-6">
        {item.videoTitle && <h2 className="mb-6 font-semibold">{item.videoTitle}</h2>}
        {item.content && <Markdown className="text-text-color-light">{item.content}</Markdown>}
      </div>
      <YoutubeVideo className="max-w-[600px] overflow-hidden rounded-2xl" videoId={item.videoId} />
    </div>
  )
}

export default ArticleVideo
