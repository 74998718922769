'use client'

import { useSearchParams } from 'next/navigation'
import React from 'react'
import { Suspense } from 'react'

type Props<T> = Omit<T, 'href' | 'children'> & { href: string; children: React.ReactElement }

// this generic component adds query params to the href of the children
// this is useful for webviews in app, where we need to keep the hideUi query param in the url
// this workaround is neccessary because of the problem with static rendering
function GenericAddQueryToHref<T>(props: Props<T>) {
  return (
    <Suspense fallback={props.children}>
      <ParamForwardingWrapper {...props} />
    </Suspense>
  )
}

function ParamForwardingWrapper<T>(props: Props<T>) {
  const searchParams = useSearchParams()

  let query = {}
  searchParams.forEach((value, key) => {
    query = { ...query, [key]: value }
  })

  return React.cloneElement(props.children, { href: { pathname: props.href, query } })
}

export default GenericAddQueryToHref
