import InputField from '@/src/components/formFields/InputField'
import { convertToHyphenatedString } from '@/src/components/strapiForm/utils'
import { InputType } from '@/src/components/strapiForm/types'

export type InputProps = {
  data: InputType
}

const FormInput: React.FC<InputProps> = ({ data }) => {
  const name = convertToHyphenatedString(data.label)
  return <InputField name={name} label={data.label} placeholder={data.placeholder} required={data.isRequired} />
}

export default FormInput
