import Cookies from 'js-cookie'
import { GROWTHBOOK_EXPERIMENT_DATA_SESSION_STORAGE_KEY } from '@/src/constants/storage'
import * as Sentry from '@sentry/nextjs'
import { ApiEndpoints } from '@/src/services/api'
import { sessionStorageJsonItem } from '@/src/utilities/storage'
import { Experiment, Result } from '@growthbook/growthbook'
import { GB_UUID_COOKIE } from 'middleware'

export const getGrowthBookDeviceId = () => {
  let cookie = Cookies.get(GB_UUID_COOKIE)
  return cookie
}

export const parseGrowthbookExperimentData = (experiment: Experiment<any>, result: Result<any>) => ({
  experimentId: experiment.key,
  experimentVersion: result.key,
})

export type ExperimentData = {
  experimentId?: string
  experimentVersion?: string
}

export const trackExperiment = async (api: ApiEndpoints, experimentId: string, experimentVersion: string) => {
  try {
    api.trackExperiment(experimentId, experimentVersion)
  } catch (e) {
    Sentry.captureException(e, { tags: { type: 'Growthbook tracking' } })
    console.log('Experiment tracking failed')
  }
}

export const {
  getSessionData: getExperimentSessionData,
  setSessionData: setExperimentSessionData,
  clearSessionData: clearExperimentSessionData,
} = sessionStorageJsonItem<ExperimentData[]>(GROWTHBOOK_EXPERIMENT_DATA_SESSION_STORAGE_KEY)

export const addExperimentSessionData = (data: ExperimentData) => {
  const currentSessionData = getExperimentSessionData()
  setExperimentSessionData([...(currentSessionData || []), data])
}
