import React from 'react'
import { ComponentNames } from '../university/componentNames'
import { getTranslate } from '../../utilities/i18n'
import Markdown from 'markdown-to-jsx'

export const textToAnchor = (text) => {
  return text
    .normalize('NFD') // Normalize to decomposed Unicode form (e.g., é -> e + ´)
    .replace(/[\u0300-\u036f]/g, '') // Remove combining diacritical marks
    .toLowerCase()
    .replace(/[^\p{L}\d\s-]+/gu, '') // Remove characters that are not letters, digits, spaces, or hyphens
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple consecutive hyphens with a single hyphen
    .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
}
const parseMarkdownHeadlines = (text) => {
  const regex = /^## (.+?)\n/gm // selects only headlines h2
  const matches: string[] = []
  let match

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[1])
  }

  return matches
}

const getAnchors = (content) => {
  const contentForHeadlines = content.filter((item) => item.__typename === ComponentNames.richContent)
  let anchors: string[] = []
  contentForHeadlines?.forEach((item) => {
    const content = parseMarkdownHeadlines(item.content)
    anchors = anchors.concat(content)
  })

  return anchors
}

const ArticleAnchors = ({ content }) => {
  const { t } = getTranslate()
  const anchors = getAnchors(content)

  if (anchors?.length === 0) {
    return null
  }

  return (
    <>
      <h5 className="text-md mb-4 font-bold text-secondary">{t(`component.counselling.anchorsTitle`)}</h5>
      <ul className="list-unstyled mb-8 flex flex-col gap-4">
        {anchors.map((headline, index) => {
          return (
            <li key={index} className="gap-4 text-sm font-bold">
              <a href={`#${textToAnchor(headline)}`}>
                <Markdown>{headline}</Markdown>
              </a>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default ArticleAnchors
