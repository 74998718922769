import React from 'react'
import GenericAddQueryToHref from './GenericQueryForwarding'
import Link, { LinkProps } from 'next/link'

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps & { href: string; children: React.ReactNode }

// this link is used mostly for webviews in app,
// where we need to keep the hideUi query param in the url
// this creates the problem with static rendering, so we need to use suspense
const LinkWithQueryForwarding: React.FC<Props> = (props) => {
  return (
    <GenericAddQueryToHref {...props}>
      <Link {...props}></Link>
    </GenericAddQueryToHref>
  )
}

export default LinkWithQueryForwarding
