import React from 'react'
import FormInput from '@/src/components/strapiForm/FormInput'
import FormSelectionsInput from '@/src/components/strapiForm/FormSelectionsInput'

type Props = { formFields: any }

const fields = {
  'form.input': FormInput,
  'form.options-input': FormSelectionsInput,
}

const FormBlockIterator: React.FC<Props> = ({ formFields }) => {
  return formFields?.map((formField, index) => {
    const name = formField.__component

    const Field = fields[name]

    return Field ? <Field key={index} data={formField} /> : null
  })
}

export default FormBlockIterator
