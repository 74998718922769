import CheckboxField from './CheckboxField'
import { useState } from 'react'
import { OptionType } from './types'
import ErrorMessage from './ErrorMessage'

type CheckboxFieldsListProps = {
  readonly options: OptionType[]
  readonly name: string
  readonly className?: string
  readonly type?: string
  readonly onClick?: () => void
}

const CheckboxFieldsList: React.FC<CheckboxFieldsListProps> = ({ options, name, className, onClick, type }) => {
  const [checkedValue, setCheckedValue] = useState(false)

  const onChange = (event) => {
    setCheckedValue(event.target.checked)
    if (onClick) {
      onClick()
    }
  }

  return (
    <>
      {options.map((item, key) => {
        return (
          <CheckboxField
            key={`${item.value}${key}`}
            label={item.label}
            value={item.value}
            name={name}
            onChange={onChange}
            checked={checkedValue}
            type={type || 'plain'}
            className={className}
          />
        )
      })}
      <ErrorMessage name={name} />
    </>
  )
}

export default CheckboxFieldsList
