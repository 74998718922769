'use client'

import {
  Carousel,
  CarouselClickItem,
  CarouselContent,
  CarouselItem,
  useCarousel,
  usePositionButton,
} from '@/src/components/pageBlocks/carousel/EmblaCarousel'
import React from 'react'
import Image from 'next/image'
import { cn } from '@/src/rebrand/utilities'
import Container from '@/src/rebrand/Container'
import { PageBlockType, SlideType } from '@/src/components/pageBlocks/types'

const ActiveLine = () => <div className="absolute -left-[2px] top-0 z-10 h-[80px] w-[3px] rounded-xl bg-primary" />

const ItemLink: React.FC<{
  text: string
  index: number
  selectedIndex: number
}> = ({ text, index, selectedIndex }) => {
  const isSelected = selectedIndex === index
  return (
    <CarouselClickItem index={index}>
      <div
        className={cn(
          'relative mb-12 h-20 cursor-pointer border-0 border-l border-dashed border-secondary',
          isSelected ? 'opacity-100' : 'opacity-50',
        )}
      >
        {isSelected && <ActiveLine />}

        <h3 className="ml-4 pb-4 text-xl font-semibold leading-7">{text}</h3>
      </div>
    </CarouselClickItem>
  )
}

const ControlItems: React.FC<{ slides: SlideType[] }> = ({ slides }) => {
  const { api } = useCarousel()
  const { selectedIndex } = usePositionButton(api)
  return (
    <>
      {(slides || []).map((slide, index) => {
        return (
          <ItemLink
            key={`slide-desktop-nav-${slide.id}`}
            text={slide.title}
            index={index}
            selectedIndex={selectedIndex}
          />
        )
      })}
    </>
  )
}

const SliderFeaturesDesktop: React.FC<{
  data: PageBlockType
}> = ({ data }) => {
  return (
    <Container>
      <Carousel
        opts={{
          align: 'start',
          loop: true,
        }}
        orientation="vertical"
        className="hidden justify-between overflow-hidden tablet:flex"
      >
        <div className="max-w-[440px] pr-40">
          <ControlItems slides={data.slide} />
        </div>
        <CarouselContent className="h-[570px]">
          {(data.slide || []).map((slide) => {
            const img = slide.imageDesktop?.data?.attributes
            return (
              <CarouselItem key={`slide-desktop-${slide.id}`}>
                {img ? (
                  <Image
                    src={img.url}
                    alt={img.alternativeText || `desktop slide ${slide.id} image`}
                    width={img.width}
                    height={img.height}
                    sizes="700px"
                    className="max-w-[700px]"
                  />
                ) : null}
              </CarouselItem>
            )
          })}
        </CarouselContent>
      </Carousel>
    </Container>
  )
}

export default SliderFeaturesDesktop
