import Image from 'next/image'
import { cn, isNewBrandingActive } from '../rebrand/utilities'

const Bubble: React.FC<{
  disableOffset?: boolean
  bgColor?: string
  imgUrl?: string
  className?: string
  sizeClass?: string
  children?: React.ReactNode
}> = ({ disableOffset, children, imgUrl, className, bgColor = 'light', sizeClass = 'w-12 h-12' }) => {
  return isNewBrandingActive ? (
    <div className={cn(`relative`, sizeClass, className)}>
      {imgUrl && (
        <Image
          sizes="128px"
          className={cn('overflow-hidden')}
          fill
          src={imgUrl}
          alt="image cover"
          style={{
            objectFit: 'cover',
            maskImage: "url('/images/ellipsis-rebrand.svg')",
            maskPosition: 'center',
            maskRepeat: 'no-repeat',
          }}
        />
      )}
      {children}
    </div>
  ) : (
    <div
      className={`relative ${sizeClass} ${className} bg-${bgColor} overflow-hidden rounded-2xl border-2 border-solid  border-white ${
        disableOffset ? '' : '-ml-4'
      }`}
    >
      {imgUrl && <Image sizes="128px" fill src={imgUrl} alt="image cover" style={{ objectFit: 'cover' }} />}
      {children}
    </div>
  )
}

export default Bubble
