'use client'

import { FormProvider, useForm } from 'react-hook-form'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import React from 'react'
import { getTranslate } from '@/src/utilities/i18n'
import SelectController from '@/src/components/formFields/SelectController'
import capitalize from 'lodash/capitalize'
import InputField from '@/src/components/formFields/InputField'
import CheckboxField from '@/src/components/formFields/CheckboxField'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useRouter } from 'next/navigation'
import { API_URL } from '@/src/utilities/apiUtils'
import toaster from '@/src/utilities/toaster'
import { getHashFromString } from '@/src/utilities/codingPublicHash'

const VoucherForm = ({ services }) => {
  const { t } = getTranslate()
  const router = useRouter()

  const serviceOptions = services
    .filter((service) => service.prices?.length > 0)
    .map((service) => {
      return {
        label: capitalize(service.name),
        value: `${service.id}`,
      }
    })

  const numberOptions = [...Array(10)].map((_, key) => {
    const val = key + 1
    return {
      label: `${val}`,
      value: `${val}`,
    }
  })

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      service: serviceOptions.reduce((lowest, item) => {
        return item.order < lowest.order ? item : lowest
      }),
      count: { label: '1', value: '1' },
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email('validationErrors.email.badEmailFormat').required('validationErrors.required'),
        consent: Yup.boolean().oneOf([true], 'validationErrors.notChecked'),
      }),
    ),
  })

  const service = form.watch('service')
  const serviceId = parseInt(service?.value, 10)
  const selectedService = services.find((item) => item.id === serviceId)
  const prices = selectedService.prices
  const count = parseInt(form.watch('count').value, 10)

  const submitForm = async (data: Record<string, any>) => {
    try {
      const res = await fetch(`${API_URL}/voucher/gift`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          count: parseInt(data.count.value, 10),
          services: [serviceId],
          email: data.email,
          params: getHashFromString(data.email),
        }),
      })
      const resData = await res.json()
      router.push(resData.transaction.redirect)
    } catch (e) {
      toaster.error('errors.defaultError')
      console.log(e, 'e')
    }
  }

  const handleSubmit = form.handleSubmit(submitForm, (e) => {
    console.log(e)
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="mb-5 w-full">
          <SelectController
            name={'service'}
            label={t('components.giftVoucher.serviceSelect')}
            options={serviceOptions}
          />
          <SelectController
            name={'count'}
            label={t('components.giftVoucher.numberOfAppointments')}
            options={numberOptions}
          />

          <InputField
            name={'email'}
            label={
              <>
                {t('components.giftVoucher.email')}&nbsp;
                <span className="text-sm font-normal normal-case">{t('components.giftVoucher.note')}</span>
              </>
            }
            required
          />

          <CheckboxField label={t(`components.giftVoucher.consent`)} name="consent" className="my-8 flex flex-col" />
        </div>

        {selectedService.prices?.length > 0 && (
          <div className="grid w-full grid-cols-1 gap-4 rounded-xl bg-sand-500 px-5 py-4 text-secondary tablet:grid-cols-2">
            <div className="flex items-center justify-between text-3xl font-semibold tablet:flex-col tablet:items-start">
              <div className="text-sm font-bold">{t('components.giftVoucher.totalCost')}</div>
              <div className="font-serif">{t('general.currency', { currency: Math.max(...prices) * count })}</div>
            </div>

            <RadixButton
              loading={form.formState.isSubmitting}
              onClick={handleSubmit}
              className="w-full tablet:w-auto"
              size="medium"
            >
              {t('component.button.payWithCard')}
            </RadixButton>
          </div>
        )}
      </form>
    </FormProvider>
  )
}

export default VoucherForm
