import { useDispatch } from 'react-redux'
import { useSession } from '../contexts/session-context'
import UserActions, { USER_TYPE } from '../redux/UserRedux'
import { UserType } from '../types/UserStateType'
import { useCallback } from 'react'

type ExtendedUserType = {
  readonly user: UserType
  readonly isClient: boolean
  readonly isTherapist: boolean
  readonly isLoadingUser: boolean
  readonly hasCompanyAccount: boolean
  readonly refreshUser: () => void
}

const useUser = (): ExtendedUserType => {
  const { user, isAuthenticating } = useSession()
  const dispatch = useDispatch()
  const refreshUser = useCallback(() => {
    dispatch(UserActions.getUserDataRequest(null, true, { event: 'loginSuccessful' }))
  }, [])

  return {
    user,
    isClient: user?.type === USER_TYPE.CLIENT,
    isTherapist: user?.type === USER_TYPE.THERAPIST,
    isLoadingUser: isAuthenticating,
    hasCompanyAccount: user?.type === USER_TYPE.CLIENT && user?.companyIds !== null && user?.companyIds?.length > 0,
    refreshUser,
  }
}

export default useUser
