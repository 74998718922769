'use client'

import { FormProvider, useForm } from 'react-hook-form'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import FormIterator from '@/src/components/strapiForm/FormIterator'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTranslate } from '@/src/utilities/i18n'
import Icon from '@/src/components/icons/Icon'
import React from 'react'
import { convertToHyphenatedString } from '@/src/components/strapiForm/utils'
import { FormType } from '../pageBlocks/types'

const generateValidationSchema = (fields) => {
  const validationSchema = {}

  fields.forEach((field) => {
    const name = convertToHyphenatedString(field.label)
    let validationRule =
      {
        text: Yup.string(),
        email: Yup.string(),
        password: Yup.string(),
        number: Yup.number(),
        tel: Yup.string(),
      }[field.type] || Yup.mixed()

    if (field.isRequired) {
      validationRule = validationRule.required(() => 'validationErrors.required')
    }

    if (field.type === 'number') {
      validationRule = validationRule.typeError('validationErrors.numeric').nullable()
    }

    if (field.type === 'email') {
      validationRule = validationRule.email('validationErrors.email.badEmailFormat')
    }

    validationSchema[name] = validationRule
  })

  return Yup.object().shape(validationSchema)
}

const Form = ({ data }) => {
  const { t } = getTranslate()
  const formData: FormType = data.form.data.attributes

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(generateValidationSchema(formData.formFields)),
  })

  const submitForm = async (data: Record<string, any>) => {
    const fields = Object.keys(data).map((key) => {
      let value = data[key]
      const field = formData.formFields.find((item) => convertToHyphenatedString(item.label) === key)

      if (field.options) {
        value = data[key].value
      }

      return {
        name: field.label,
        value,
      }
    })

    await fetch('/api/form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formName: formData.name,
        fields,
      }),
    })
  }

  const handleSubmit = form.handleSubmit(submitForm, (e) => {
    console.log(e)
  })

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        {form.formState.isSubmitSuccessful ? (
          <div className="flex items-center rounded-lg bg-blue-500 px-2 py-4 text-base font-semibold">
            <Icon icon="check-inline" color="secondary" className="mr-2" />
            {t('components.pageBlocks.form.formSent')}
          </div>
        ) : (
          <>
            <div className="mb-5 w-full">
              <FormIterator formFields={formData.formFields} />
            </div>

            {data.submitText && (
              <RadixButton
                loading={form.formState.isSubmitting}
                onClick={handleSubmit}
                className="w-full"
                size="medium"
              >
                {data.submitText}
              </RadixButton>
            )}
          </>
        )}
      </form>
    </FormProvider>
  )
}

export default Form
