import Markdown from 'markdown-to-jsx'
import { be } from '../../utilities/bliss'

const AnchorSection = ({ section }) => {
  return (
    <>
      <span id={section.anchorName} className={be('anchor', 'section')} />
      <h2>{section.title}</h2>
      {section.content && <Markdown>{section.content}</Markdown>}
    </>
  )
}

export default AnchorSection
