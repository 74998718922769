'use client'
import { addExperimentSessionData, parseGrowthbookExperimentData } from '@/src/utilities/growthBookUtils'
import { TrackingCallback, TrackingData } from '@growthbook/growthbook'
import { useEffect, useRef } from 'react'

export const onExperimentView: TrackingCallback = (experiment, result) => {
  const { experimentId, experimentVersion } = parseGrowthbookExperimentData(experiment, result)

  // we only store them here since Hedepy API calls are currently only possible in the authenticated part
  addExperimentSessionData({ experimentId, experimentVersion })

  console.log('Viewed Experiment', {
    experimentId: experiment.key,
    variationId: result.key,
  })
}

// Helper component to track experiment views from server components
export function GrowthBookTracking({ data }: { data: TrackingData[] }) {
  const isFirstRender = useRef(true)

  useEffect(() => {
    // for some reason, this is called twice
    if (isFirstRender.current) {
      data.forEach(({ experiment, result }) => {
        onExperimentView(experiment, result)
      })
      isFirstRender.current = false
    }
  }, [data])

  return null
}
