import { pathOr } from 'ramda'
import createResultCheckingSelector from '../memoize'
import type { ReduxState } from '../reducers'
import type { ServiceType } from '../../types/GeneralStateType'

const fetchingFilter = (state: ReduxState, key) => pathOr(0, ['general', 'fetching', key], state)

export const servicesSelector = (state: ReduxState) => pathOr([], ['general', 'services'], state)

export const fetchingSelector: (state: ReduxState, key: string) => boolean = createResultCheckingSelector(
  fetchingFilter,
  (fetching: number) => fetching > 0,
)

export const diagnosisServices: (state: ReduxState) => {
  main: ServiceType[]
  minor: ServiceType[]
  hidden: ServiceType[]
  instant: ServiceType[]
} = createResultCheckingSelector(servicesSelector, (services) =>
  services.reduce((acc, current) => {
    const service = { ...current, value: current.id, label: current.name }
    if (acc[current.type]) {
      acc[current.type].push(service)
    } else {
      acc[current.type] = [service]
    }
    return acc
  }, {}),
)

export default {
  servicesSelector,
  fetchingSelector,
  diagnosisServices,
}
