const useFlags = () => {
  const enableUniversity = process.env.NEXT_PUBLIC_FLAG_UNIVERSITY === 'true'
  const enableStatistics = process.env.NEXT_PUBLIC_FLAG_STATISTICS === 'true'
  const showReferral = process.env.NEXT_PUBLIC_FLAG_SHOW_REFERRAL === 'true'
  const allowBankTransfer = process.env.NEXT_PUBLIC_FLAG_HIDE_BANK_TRANSFER !== 'true'
  const allowKlarnaTransfer = process.env.NEXT_PUBLIC_FLAG_ALLOW_KLARNA === 'true'
  const allowPrzelewyTransfer = process.env.NEXT_PUBLIC_FLAG_ALLOW_PRZELEWY === 'true'
  const allowBlikTransfer = process.env.NEXT_PUBLIC_FLAG_ALLOW_BLIK === 'true'
  const allowBenefitPayment = process.env.NEXT_PUBLIC_FLAG_SHOW_BENEFIT_PAYMENT === 'true'
  const hidePaypal = process.env.NEXT_PUBLIC_FLAG_HIDE_PAYPAL === 'true'
  const firstEventForFree = process.env.NEXT_PUBLIC_FLAG_BOOK_FIRST_EVENT_FREE === 'true'
  const showAppleLogin = process.env.NEXT_PUBLIC_FLAG_SHOW_APPLE_LOGIN === 'true'
  const allowCounselling = process.env.NEXT_PUBLIC_FLAG_COUNSELLING === 'true'
  const allowArticles = process.env.NEXT_PUBLIC_FLAG_ARTICLES === 'true'
  const allowBrainee = process.env.NEXT_PUBLIC_FLAG_ARTICLES_BRAINEE === 'true'
  const allowPoshurshemo = process.env.NEXT_PUBLIC_FLAG_ARTICLES_POSHURSHEMO === 'true'
  const allowAtairu = process.env.NEXT_PUBLIC_FLAG_ARTICLES_ATAIRU === 'true'
  const allowNewContent = process.env.NEXT_PUBLIC_FLAG_NEW_CONTENT === 'true'
  const allowInstantTherapy = process.env.NEXT_PUBLIC_FLAG_INSTANT_THERAPY === 'true'
  const requireClientAddress = process.env.NEXT_PUBLIC_FLAG_REQUIRE_CLIENT_ADDRESS === 'true'
  const showPlaceCategoryInAddress = process.env.NEXT_PUBLIC_FLAG_ADDRESS_PLACE_CATEGORY === 'true'
  const hideCompanyBilling = process.env.NEXT_PUBLIC_FLAG_HIDE_COMPANY_BILLING === 'true'
  const showNewTherapistAcademy = process.env.NEXT_PUBLIC_FLAG_NEW_THERAPIST_ACADEMY === 'true'
  const hideNonVisibleTherapists = process.env.NEXT_PUBLIC_FLAG_HIDE_NON_VISIBLE_THERAPISTS === 'true'
  const showCounsellingTeaser = process.env.NEXT_PUBLIC_FLAG_SHOW_COUNSELLING_TEASER === 'true'
  const showNewSubscriptionPage = process.env.NEXT_PUBLIC_FLAG_NEW_SUBSCRIPTION_PAGE === 'true'
  const allowNps = process.env.NEXT_PUBLIC_FLAG_NPS === 'true'
  const allowMessages = true
  const isSubscriptionOn = true
  const showBulkInvoiceDownload = true
  const allowTherapistDiagnosis = true
  const allowEmailChange = true
  const allowTherapistRegister = true

  return {
    showReferral,
    isSubscriptionOn,
    allowBenefitPayment,
    allowBankTransfer,
    allowKlarnaTransfer,
    allowPrzelewyTransfer,
    allowBlikTransfer,
    hidePaypal,
    firstEventForFree,
    allowMessages,
    allowCounselling,
    allowArticles,
    allowInstantTherapy,
    requireClientAddress,
    showPlaceCategoryInAddress,
    hideCompanyBilling,
    showBulkInvoiceDownload,
    allowTherapistDiagnosis,
    allowEmailChange,
    allowTherapistRegister,
    enableUniversity,
    enableStatistics,
    showNewTherapistAcademy,
    showCounsellingTeaser,
    hideNonVisibleTherapists,
    showAppleLogin,
    allowBrainee,
    allowPoshurshemo,
    allowAtairu,
    allowNewContent,
    showNewSubscriptionPage,
    allowNps,
  }
}

export default useFlags
