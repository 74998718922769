import { CheckoutParamsType } from '../components/reservation/reservationV2/utils/parseCheckoutParams'

export enum NotifyAppMessages {
  LESSON_CLOSE = 'lesson_detail_close',
  PAYMENT_FAILURE = 'payment_failure',
  PAYMENT_SUCCESS = 'payment_success',
  PREBOOKING_CHOOSE_DIFFERENT = 'prebooking_choose_different',
  PREBOOKING_EMPTY = 'prebooking_empty',
  EVENT_DATE_CHANGE_SUCCESS = 'event_date_change_success',
  DOWNLOAD_INVOICE = 'download_invoice',
  PAYMENT_CLOSE = 'payment_close',
  OPEN_WEBVIEW = 'open_webview',
  OPEN_BROWSER = 'open_browser',
  OPEN_EMAIL = 'open_mail',
  OPEN_PHONE = 'open_phone',
  NAVIGATE_TO_CHAT = 'navigateToChat',
  PAYMENT_APPLE = 'payment_apple',
  PAYMENT_GOOGLE = 'payment_google',
}

type NotifyOptionsType = {
  url?: string
  fullScreen?: boolean
  param?: string
  email?: string
  phone?: string
  userId?: number
  paymentParams?: CheckoutParamsType
  intentClientSecret?: string
}

const notifyMobileApp = (event: NotifyAppMessages, options?: NotifyOptionsType) => {
  const stringifiedMessage: string = options ? JSON.stringify({ event, ...options }) : event

  // @ts-expect-error this object is injected by the mobile app
  if (typeof Print !== 'undefined') {
    // @ts-expect-error this object is injected by the mobile app
    Print.postMessage(stringifiedMessage)
  }
}

export default notifyMobileApp
