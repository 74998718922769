import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

type Props = {
  readonly citation: {
    citation: string
    author: {
      name: string
      instagramName: string
      instagramLink: string
      thumbnail: {
        data: {
          attributes: {
            url: string
          }
        }
      }
    }
  }
  readonly isPublic?: boolean
}

const Citation: React.FC<Props> = ({ citation, isPublic = false }) => {
  const author = citation?.author
  const image = author?.thumbnail?.data?.attributes?.url
  return (
    <div className="tablet:w-1/2">
      <div className={`m-2 rounded-2xl ${isPublic ? 'bg-petroleum-background' : 'bg-white'} p-8 font-bold`}>
        <div className="mb-4 text-petroleum">{citation?.citation}</div>
        <div className="flex flex-wrap items-center">
          {image && (
            <div className="relative mr-3 h-[70px] w-[70px] overflow-hidden !rounded-full bg-white">
              <Image src={image} alt={author?.name} fill objectFit="cover" sizes="70px" />
            </div>
          )}
          <div>
            {author?.instagramLink ? (
              <Link href={author.instagramLink}>
                <div className="text-peach">{author?.name}</div>
              </Link>
            ) : (
              <div className="text-peach">{author?.name}</div>
            )}
            <div className="text-text-color-light">{author?.instagramName}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Citation
