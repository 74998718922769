import { useSearchParams } from 'next/navigation'

export const HIDE_UI_PARAM_NAME = 'hideUi'

const useIsAppWebView = () => {
  const searchParams = useSearchParams()
  const shouldHideUi = searchParams.get(HIDE_UI_PARAM_NAME) === 'true'
  return shouldHideUi
}

export default useIsAppWebView
