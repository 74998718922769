import { toast } from 'react-toastify'
import { Translate } from 'next-translate'

class Toaster {
  t: any

  setTranslator = (t: Translate) => {
    this.t = t
  }

  error = (error: string, actions?: any, buttonText?: string): void => {
    toast.error(this.t(error, {}, { fallback: 'errors.defaultError' }))
    return
  }

  warning = (warning: string): void => {
    toast.warn(this.t(warning))
  }

  info = (message: string): void => {
    toast.info(this.t(message))
  }

  success = (message: string): void => {
    toast.success(this.t(message))
  }

  defaultMessage = (message: string): void => {
    toast(this.t(message))
  }
}

export default new Toaster()
